import "../Styles/index.scss";

import $ from "jquery";

import imagesLoaded from "imagesloaded";
(<any>imagesLoaded).makeJQueryPlugin($);
import { Spinner } from "spin.js";
import { RecaptchaForm } from "@wagich/bluc-recaptchaform-client";
import Swal from "sweetalert2";
import "./vendor/orbit/jquery.foundation.orbit.js";
import "./vendor/jquery/jquery.ui.effect.js";
import { setupMaps } from "./map";

let mq = {
	small: "(min-width: 25em)",
	medium: "(min-width: 54em)",
	large: "(min-width: 80em)",
	xlarge: "(min-width: 110em"
};

/* --- CONTENT --- */
function setContentPosition() {
	if ($(window).height()! > $("#content").outerHeight()!) {
		$("#contentScrollContainer").css({ top: "auto" });
	} else {
		$("#contentScrollContainer").css({ top: 0 });
	}
}
setContentPosition();

let logo = $(".logo-wrapper");
let contentScrollContainer = $("#contentScrollContainer");
contentScrollContainer.on("scroll", (e) => {
	window.requestAnimationFrame(updateLogoOpacity);
});
function updateLogoOpacity() {
	let opacity = Math.max(1 - (contentScrollContainer.scrollTop()! / 100), 0);
	logo.css({ opacity: opacity });
}

/* --- NAVIGATION --- */

$(".nav-btn").click(function () {
	$(".nav-toggle").toggleClass("nav-toggle-closed");
});

$("#navigation > li:has(ul) > a").on("click", function (e) {
	let item = $(e.currentTarget).parent();
	$("#navigation > li.is-open").not(item).removeClass("is-open");
	item.toggleClass("is-open");

	e.preventDefault();
});

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind(".is-zoomable");

setupMaps();

/* --- GALLERY --- */
if ($("#slideContainer").length > 0) {
	$(".slide").height($("#sidebar").height()!);

	let spinner = new Spinner({ color: "#666666" });
	spinner.spin(document.getElementById("sidebar")!);

	$(window).resize(function () {
		resetGalleryWidth();
	});

	$("#slideContainer img:first").imagesLoaded().always(images => {
		let $sidebar = $("#sidebar");
		let $slide = $("#slideContainer .slide");
		setSlideAndImageDimensions($slide, $sidebar.width()!, $sidebar.height()!);
		animateGalleryWidth(images.elements[0].width, 0);
	});

	$("#slideContainer").orbit({
		timer: false,
		afterLoadComplete: function () {
			let orbit = this;

			spinner.stop();
			resetGalleryWidth();

			let maxWidth = $("#sidebar").width();
			let maxHeight = $("#sidebar").height();

			orbit.orbitWidth = maxWidth;
			orbit.orbitHeight = maxHeight;

			setGalleryWidth(orbit);

			$(window).resize(function () {
				setGalleryWidth(orbit);
			});

			if (window.location.hash.length > 0) {
				let idFromHash = window.location.hash.substring(1);
				let slideIndex = orbit.$slides.index(orbit.$slides.filter(`[data-id="${idFromHash}"]`));
				orbit.shift(slideIndex);
			}

			function setGalleryWidth(orbit: any) {
				setSlideImageDimensions(orbit);
				let $currentSlide = $(orbit.$slides[orbit.activeSlide]);

				let newWidth = $currentSlide.children("img").width()!;
				animateGalleryWidth(newWidth, 0);
			}

			function setSlideImageDimensions(orbit: any) {
				orbit.$slides.each(function (this: any) {
					setSlideAndImageDimensions($(this), orbit.orbitWidth, orbit.orbitHeight);
				});
			}
		},
		beforeSlideChange: function (prevSlide: any, nextSlide: any) {
			let orbit = this;
			let newWidth = $(nextSlide).children("img").width()!;

			orbit.orbitWidth = newWidth;
			animateGalleryWidth(newWidth, orbit.options.animationSpeed);
		}
	});
}

function setSlideAndImageDimensions($slide: JQuery, boundingWidth: number, boundingHeight: number) {
	let $image = $slide.children("img").eq(0);

	// clone original image and append it to <body/> so proper dimensions can be read
	let $imageClone = $image.clone().appendTo("body");

	let originalWidth = $imageClone.width()!;
	let originalHeight = $imageClone.height()!;

	$imageClone.remove();

	let scaleWidth = boundingWidth / originalWidth;
	let scaleHeight = boundingHeight / originalHeight;
	let scaleFactor = Math.min(scaleWidth, scaleHeight);

	$slide.width(boundingWidth).height(boundingHeight);
	$image.width(originalWidth * scaleFactor).height(originalHeight * scaleFactor);
}

function resetGalleryWidth() {
	if (window.matchMedia(mq.large).matches) {
		$("#sidebar").css({ width: "60%" });
		$("#contentContainer").css({ right: "60%" });
	}
}

function animateGalleryWidth(width: number, duration: number) {
	if (window.matchMedia(mq.large).matches) {
		$("#sidebar").animate({ width: width }, duration);
		$("#contentContainer").animate({ right: width }, duration);
	}
}

$(window).resize(function () {
	setContentPosition();

	if (!window.matchMedia(mq.large).matches) {
		$("#sidebar").css({ width: "" });
		$("#contentContainer").css({ right: "" });
	}
});

let contactForm = document.querySelector("form#contactForm") as HTMLFormElement;
if (contactForm != null) {
	new RecaptchaForm({
		formElement: contactForm,
		baseUrl: "https://forms.bluc.io/api/",
		targetName: "vonag-web2_kontaktformular",
		token: "5ee8394d-4bd7-f037-86b6-c2ce5e0cc199",
		overlayColor: "rgba(63, 66, 75, 0.7)",
		spinnerOptions: {
			color: "#0073c0",
		},
		onError: problem => {
			Swal.fire("Fehler beim Senden", `<p>Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns direkt unter <a href="mailto:info@von-ag.ch">info@von-ag.ch</a></p><p><small><em>${problem.title || ""}</em><br />${problem.detail || ""}</small><p>`, "error");
		},
		onSuccess: form => {
			Swal.fire("Nachricht abgeschickt", `<p>Vielen Dank für Ihre Nachricht.</p>`, "success");
		}
	});
}
